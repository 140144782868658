.screenshot-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  /* padding: 6rem 4rem; */
}

.title-container-screenshot {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0;
  margin-top: 1rem;
  gap: 2rem;
}

.title-image {
  height: 6vh;
  margin-bottom: 1rem;
  max-width: 90%;
}
.screenshot-caroused-container {
  width: 100%;
  height: 100%;
  margin-left: 2rem;
}

.screenshot-screen-title {
  height: 10%;
  width: 70%;
  margin-top: 0;
  margin-bottom: 0.6rem;
}

@media (max-width: 550px) {
  .title-image {
    height: 8vh;
  }

  .screenshot-screen-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .screenshot-caroused-container {
    margin-left: 0;
  }
}
