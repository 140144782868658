.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  background-color: #f8f7f2;
  padding: 1.2rem 0;
  z-index: 1002;
}

.header.hidden {
  transform: translateY(-100%);
}

.header.visible {
  transform: translateY(0);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.logo {
  height: 28px !important;
  padding-left: 4.2rem;
}

.nav a {
  margin: 0 15px;
  text-decoration: none;
  color: #000;
  font-weight: 500;
}

.actions {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 4.2rem;
}

.sign-in-button {
  background-color: white;
}

.sign-in-button a {
  color: var(--brown);
  font-weight: 600;
}

@media (max-width: 600px) {
  .sign-in-button {
    display: none !important;
  }

  .header {
    height: 50px;
  }

  .logo {
    padding-left: 20px;
  }

  .actions {
    padding-right: 20px;
  }
}
