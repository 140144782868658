.demo-button-large {
  height: 56px !important;
}

.demo-button-small {
  height: 40px !important;
}

.demo-button-large a {
  font-weight: 600;
  /* font-size: 1.2rem; */
}

.demo-button-small a {
  font-weight: 600;
  /* font-size: 1rem; */
}

.demo-button-black-white {
  background: white !important;
}

.demo-button-black-white a {
  color: black;
}

.demo-button-brown-white {
  background: white !important;
}

.demo-button-brown-white a {
  color: #5e1b0c;
}

.demo-button {
  background-color: var(--brand-orange-1);
  border: none;
}

.image-viewer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3001;
}

.image-viewer-modal {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  /* font-size: 24px; */
  color: black;
  cursor: pointer;
}

.modal-image {
  max-width: 100%;
  max-height: 100vh;
  display: block;
}

@media screen and (max-width: 560px) {
  .demo-button-large {
    height: 40px !important;
  }

  .demo-button-small {
    height: 32px !important;
  }

  .demo-button-large a {
    /* font-size: 1.5rem !important; */
    font-weight: 600;
  }

  .demo-button-small a {
    /* font-size: 1.2rem !important; */
    font-weight: 600;
  }

  .image-viewer-overlay {
    background: rgba(0, 0, 0, 0.9);
  }

  .image-viewer-modal {
    max-width: 100%;
    max-height: 100%;
  }
}
