.services-section {
  position: relative;
  background-color: #fe6533; /* Background color neeche */
  height: 88vh;
  padding: 3rem 0;
}

.services-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../../assets/utilities/ServicesBg.svg') center center / cover no-repeat;
  opacity: 0.1; /* Yeh value adjust kar sakte ho (0 se 1 ke beech) */
  z-index: 0;
}

.services-section-title {
  color: #ffffff;
  margin: 0;
  z-index: 1001;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Items ko horizontally center align karega */
  gap: 4rem;
  row-gap: 1.5rem;
  /* padding: 4rem 0; */
  width: 60%;
  z-index: 1001;
}

.services-container p {
  margin-top: 0;
}

.service-card .service-name {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
}

.service-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loved-section {
  color: #5e1b0c;
  background: #ffffff;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  z-index: 1001;
  border-radius: 20px !important;
}

.loved-section p {
  font-size: 1.75rem;
  font-weight: 500;
  margin: 0;
}

.loved-section img {
  height: 1.75rem;
  width: 1.75rem;
}

@media (min-width: 801px) {
  .service-card {
    width: calc(100% / 6 - 3rem); /* 6 columns with spacing */
  }
}

/* mobile */
@media (max-width: 800px) {
  .services-section {
    padding: 5rem 0;
  }
  .services-section-title {
    width: 90%;
  }
  .services-container {
    grid-template-columns: repeat(3, 1fr);
    width: 80%;
  }

  .service-round-icon-container img {
    height: 80px;
    width: 80px;
    padding: 1rem;
  }

  .service-name {
    font-size: 14px !important;
  }

  .loved-section {
    flex-direction: column;
    width: 70%;
    text-align: center;
  }

  .loved-section img {
    height: 24px;
    width: 24px;
  }

  .loved-section p {
    font-size: 24px !important;
  }

  .service-card {
    width: calc(100% / 3 - 3rem); /* 3 columns with spacing */
  }
}
