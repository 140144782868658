#landing-page {
  padding-top: 9rem;
}

.main-content {
  padding: 2rem;
  background-color: var(--primary-bg-color);
}

.landing-section {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.title {
  font-family: Archivo;
  font-weight: 600;
  font-size: 4rem;
  text-align: center;
  color: #121212;
  margin: 0;
}

.subtitle {
  font-family: Inter;
  font-weight: 300;
  font-size: 1.5rem;
  letter-spacing: 0px;
  text-align: center;
  color: #616161;
  margin-bottom: 1rem;
  width: 70%;
}

.details-section {
  margin-top: 40px;
  text-align: center;
}

.screenshot {
  max-width: 70%;
  border-radius: 8px;
}

.landing-demo-button {
}

.landing-demo-button a {
}

@media (max-width: 800px) {
  .landing-section {
    width: 80%;
  }
  .subtitle {
    margin-bottom: 10px;
    width: 90%;
    font-size: 2rem;
  }
  .title {
    margin-bottom: 5px;
    font-size: 42px;
  }
  .screenshot {
    max-width: 100%;
    border-radius: 8px;
  }

  #landing-page {
    padding-top: 16rem;
  }
}
