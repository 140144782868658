.tab-card {
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative; /* Ensure relative positioning for absolute children */
  overflow: hidden;
  padding: 1rem 1rem;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); */
}

.tab-card h2 {
  font-weight: 400;
  font-size: 28px;
  text-align: left;
  margin: 0;
}

.tab-button-div {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.tab-add-button {
  position: relative;
  padding: 0;
  color: black;
  background-color: white;
  transition: opacity 0.3s ease-in-out;
}

.tab-add-icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.tab-add-text {
  white-space: nowrap;
  opacity: 0;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  display: none;
  margin-left: 8px;
}

@media (min-width: 1000px) {
  /* Show Read More button on card hover */
  .tab-card:hover .tab-add-button {
    width: 130px;
    border-radius: var(--default-button-border-radius);
    height: var(--large-button-height);
    justify-content: space-evenly;
    padding: 0 12px;
  }

  /* Show text on hover */
  .tab-card:hover .tab-add-text {
    display: inline;
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 560px) {
  .tab-card {
    height: 10vh;
    padding: 2rem;
  }

  .tab-card h2 {
    font-size: 24px;
  }

  .tab-button-div {
    right: 2rem;
    bottom: 2rem;
  }
}
