.pricing-page-demo {
  background: #fe6533;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 4rem 0; */
  position: relative;
  gap: 2rem;
}

.pricing-page-demo::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../../assets/utilities/footer_bg.svg') center center / cover no-repeat;
  opacity: 0.1; /* Yeh value adjust kar sakte ho (0 se 1 ke beech) */
  z-index: 0;
}

.pricing-card-container {
  display: flex;
  flex-direction: column;
  height: 60%;
  background-color: white;
  padding: 1rem 4rem;
}

.pricing-cards-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.pricing-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.pricing-title {
  font-size: 3rem;
  color: #ffffff;
  text-align: center;
  font-weight: 500;
  margin: 0;
  width: 70%;
  line-height: normal;
  z-index: 1001;
}

.pricing-page-demo-button {
  z-index: 1001;
}

@media (max-width: 550px) {
  .pricing-title {
    width: 90%;
  }

  .pricing-cards-container {
    flex-direction: column;
    gap: 20px;
  }
}
