.highlight-container {
  background: linear-gradient(175deg, #f8f7f2 18%, #fe6533 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  /* padding: 4rem 4rem; */
}

.highlight-center {
  width: 100%;
  height: 100%;
}

.section-title-container {
  margin-bottom: 3rem;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60vh;
  margin: 0 0.1rem;
  position: relative;
}

.highlight-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.5rem;
}

.page-container .section-navigate-icon {
  background-color: white;
}

.page-container .section-navigate-icon a {
  color: black !important;
}

.page-title {
  width: 80%;
  margin-top: 0;
  font-size: 2rem;
  text-align: left;
  font-weight: 400;
  margin-bottom: 1rem;
}

.page-image {
  width: 100%;
  align-items: center;
  /* overflow: hidden; */
}

.page-container .mobile-image {
  display: none;
}

.section-navigate-button {
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  background: #fff;
  border-radius: 28px;
  padding: 12px 24px;
}

.section-navigate-button a {
  color: black;
  /* font-size: 20px; */
  font-weight: 500;
}

@media (max-width: 550px) {
  .page-container {
    height: 75vh;
    width: 90vw;
    overflow: hidden !important;
    margin: 0 auto;
    padding: 0;
  }
  .page-title {
    width: 90%;
    font-size: 28px;
    margin-left: 1rem;
  }

  .highlight-card-header {
    padding: 1.5rem;
  }

  .highlight-container {
    padding: 4rem 0;
  }

  .page-container .desktop-image {
    display: none;
  }

  .page-container .btn-round {
    height: 42px !important;
    width: 42px !important;
  }
}
