.colorpage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* padding: 6rem 3.5rem; */
}

.colorpage-title {
  height: 8%;
  width: 60%;
  margin-top: 0;
  margin-bottom: 2rem;
}

.colorpage-image {
  /* max-width: 80%; */
  width: 90%;
  margin-bottom: 2rem;
}

.colorpage-text {
  width: 60%;
}

.security-text {
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

.support-card-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  width: 80%;
  margin: 0 auto;
}

.support-card {
  background: white;
  border-radius: 1rem;
  display: flex;
  gap: 1rem;
  justify-items: start;
  align-items: flex-start;
  padding: 1rem 1.75rem;
}

.support-card p {
  font-weight: 400;
  margin: 0;
}

.support-card img {
  height: 1.5rem;
  width: 1.5rem;
  padding: 1rem;
}

.support-round-icon-container {
  background-color: red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 800px) {
  .colorpage-title {
    width: 90%;
  }

  .colorpage-text {
    width: 90%;
  }

  .security-text {
    width: 90%;
  }

  .support-card-container {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .support-card {
    align-items: center;
  }
}
