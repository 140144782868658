.carousel-child {
  margin: 0 auto;
}

.custom-navigation-button {
  border: none;
  background-color: black;
  color: white;
  margin-top: 1rem;
}

.swiper-button-disabled {
  background-color: #1a1a1a0d;
  cursor: not-allowed;
}

.swiper-button-next .swiper-button-prev {
  display: none;
}
